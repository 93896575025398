<template>
	<div class="page-container">
		<div class="page-main">
			<div class="item">
				<div class="item-head">{{$t('liveplatform.coupon.pageName1')}}</div>
				<div class="item-main">
					<div class="item-desc">{{$t('liveplatform.coupon.pageTips1')}}</div>
					<div class="item-text">
						<span>{{$t('liveplatform.coupon.pageText1')}}</span>
						<el-button @click="handleDialogShow(1)" type="uudanger" size="small" round>{{$t('liveplatform.coupon.selectBtn')}}</el-button>
						<template v-if="newCoupon">
							<span class="coupon-name">{{newCoupon.name}}</span>
							<a :href="$workBasePath+'/home/market/coupon-list'" target="_blank" class="item-link">{{$t('liveplatform.coupon.seeBtn')}}</a>
						</template>
					</div>
					<div class="item-foot">
						<el-button type="uudanger" @click="handleConfirmEffect(1)" size="small" round>{{$t('liveplatform.coupon.effectBtn')}}</el-button>
						<el-button v-if="newCoupon" type="uublock" @click="handleCancelEffect(1)" size="small" round>{{$t('liveplatform.coupon.deleteBtn')}}</el-button>
					</div>
				</div>
			</div>
			<div class="item">
				<div class="item-head">{{$t('liveplatform.coupon.pageName2')}}</div>
				<div class="item-main">
					<div class="item-desc">{{$t('liveplatform.coupon.pageTips2')}}</div>
					<div class="item-text">
						<span>{{$t('liveplatform.coupon.pageText2')}}</span>
						<el-button @click="handleDialogShow(2)" type="uudanger" size="small" round>{{$t('liveplatform.coupon.selectBtn')}}</el-button>
						<template v-if="buyCoupon">
							<span class="coupon-name">{{buyCoupon.name}}</span>
							<a :href="$workBasePath+'/home/market/coupon-list'" target="_blank" class="item-link">{{$t('liveplatform.coupon.seeBtn')}}</a>
						</template>
					</div>
					<div class="item-foot">
						<el-button type="uudanger" @click="handleConfirmEffect(2)" size="small" round>{{$t('liveplatform.coupon.effectBtn')}}</el-button>
						<el-button v-if="buyCoupon" type="uublock" @click="handleCancelEffect(2)" size="small" round>{{$t('liveplatform.coupon.deleteBtn')}}</el-button>
					</div>
				</div>
			</div>
		</div>
		<div class="page-bar"><img src="@/assets/images/liveplatform/market-tps-204-360.png" width="100%"></div>
		<el-dialog :title="$t('liveplatform.coupon.dialogTitle')" :visible.sync="dialogVisible" width="900px" :before-close="handleDialogClose">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane :label="$t('liveplatform.coupon.tabPane1')" name="1">
					<div class="dialog-equity">
						<el-scrollbar>
							<div class="uu-screen">
								<ul>
									<li>
										<span>{{$t('liveplatform.screen.couponType')}}</span>
										<el-select v-model="screenType" :placeholder="$t('liveplatform.placeholder.all')" size="mini" clearable @change="handleCcreenTypeChange">
											<el-option v-for="(item,index) in typeOptions" :key="index" :label="item.label" :value="item.value"></el-option>
										</el-select>
									</li>
								</ul>
								<div class="action">
									<el-button type="primary" @click="handleCreateCoupon" size="mini">{{$t('liveplatform.coupon.createBtn')}}
									</el-button>
								</div>
							</div>
							<el-table
								ref="singleTable"
								:data="couponList"
								size="small"
								style="width: 100%;">
								<el-table-column width="55" type="index" align="center">
									<template slot-scope="scope">
										<el-radio v-model="checkCoupon" :label="JSON.stringify(scope.row)">{{ }}</el-radio>
									</template>
								</el-table-column>
								<el-table-column :label="$t('liveplatform.tableHead.couponName')" prop="name"></el-table-column>
								<el-table-column :label="$t('liveplatform.tableHead.couponMoney')">
									<template slot-scope="scope">{{scope.row.money}}{{$t('liveplatform.unit.price')}}</template>
								</el-table-column>
								<el-table-column :label="$t('liveplatform.tableHead.couponQuota')">
									<template slot-scope="scope">{{scope.row.quota}}{{$t('liveplatform.unit.price')}}</template>
								</el-table-column>
								<el-table-column :label="$t('liveplatform.tableHead.couponGiveTime')">
									<template slot-scope="scope">
										{{formatDate(scope.row.give_begin_time)}}
										<span>-</span>
										{{scope.row.give_end_time>0?formatDate(scope.row.give_end_time):formatDate(scope.row.use_end_time)}}
									</template>
								</el-table-column>
								<el-table-column :label="$t('liveplatform.tableHead.couponUseTime')">
									<template slot-scope="scope">
										{{formatDate(scope.row.use_begin_time)}}
										<span>-</span>
										{{formatDate(scope.row.use_end_time)}}
									</template>
								</el-table-column>
								<el-table-column :label="$t('liveplatform.tableHead.couponLimitGive')">
									<template slot-scope="scope">
										<template v-if="scope.row.limit_give_num>0">{{scope.row.limit_give_num}}</template>
										<template v-else>{{$t('liveplatform.coupon.unlimited')}}</template>
									</template>
								</el-table-column>
								<el-table-column :label="$t('liveplatform.tableHead.couponIssuableNum')">
									<template slot-scope="scope">
										{{scope.row.issuable_num}}
									</template>
								</el-table-column>
								<el-table-column :label="$t('liveplatform.tableHead.couponGiveNum')" prop="give_num"></el-table-column>
							</el-table>
						</el-scrollbar>
					</div>
				</el-tab-pane>
			</el-tabs>
			<div class="dialog-foot">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page.sync="pagecurr"
					:page-size="pagesize"
					layout="total, prev, pager, next"
					:prev-text="$t('liveplatform.pagination.prevText')"
					:next-text="$t('liveplatform.pagination.nextText')"
					:total="listTotal">
				</el-pagination>
			</div>
			<div slot="footer">
				<el-button type="primary" @click="handleDialogConfirm" size="mini">{{$t('liveplatform.coupon.confirmBtn')}}</el-button>
				<el-button size="mini" @click="handleDialogClose">{{$t('liveplatform.coupon.cancelBtn')}}</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { getCouponList, fetchCoupon, activeCoupon, cancelCoupon } from '@/api/liveplatform';
	export default {
		data() {
			return {
				newCoupon: '',
				buyCoupon: '',
				dialogVisible: false,
				activeName: '1',
				screenType: '',
				pagecurr: 1,
				pagesize: 10,
				listTotal: 0,
				couponList: [],
				couponType: '',
				checkCoupon: '',
				checkCouponId: '',
				newCouponId: 0,
				buyCouponId: 0,
				typeOptions: this.$t('liveplatform.coupon.couponTypeJson')
			}
		},
		created() {
			this.getCouponList();
			this.getDeployCoupon();
		},
		methods: {
			getCouponList() {
				let param = {
					type: this.screenType,
					page_index: (this.pagecurr-1)*this.pagesize,
					page_size: this.pagesize,
					app_key: this.$store.getters.appKey
				}
				getCouponList(param).then(response => {
					this.couponList = response.data.list;
					this.listTotal = parseInt(response.data.total);
				})
			},
			handleSizeChange(val){
				this.pagesize = val;
			},
			handleCurrentChange(val){
				this.pagecurr = val;
				this.getCouponList();
			},
			getDeployCoupon() {
				fetchCoupon({app_key: this.$store.getters.appKey}).then(response => {
					const { data } = response;
					this.newCoupon = data.news?data.news:'';
					this.newCouponId = data.news?data.news.live_coupon_setting_id:0;
					this.buyCoupon = data.after?data.after:'';
					this.buyCouponId = data.after?data.after.live_coupon_setting_id:0;
				})
			},
			handleCcreenTypeChange(val){
				this.pagecurr = 1;
				this.getCouponList();
			},
			handleDialogShow(type) {
				this.couponType = type;
				this.checkCoupon = '';
				this.dialogVisible = true;
			},
			handleDialogClose() {
				this.dialogVisible = false;
			},
			handleDialogConfirm(){
				if(!this.checkCoupon){
					this.$message.error(this.$t('liveplatform.errorMsg.selectBenefits'));
					return false;
				}
				switch(this.couponType){
					case 1:
					this.newCoupon = JSON.parse(this.checkCoupon);
					break;
					case 2:
					this.buyCoupon = JSON.parse(this.checkCoupon);
					break;
				}
				this.dialogVisible = false;
			},
			handleClick(tab, event) {},
			handleCreateCoupon() {
				window.open(this.$workBasePath+'/home/market/tools');
			},
			formatDate(val){
				if(val>0){
					let date = new Date(val*1000);
					let y = date.getFullYear();
					let m = date.getMonth()+1;
					m = m < 10 ? ('0' + m) : m;
					let d = date.getDate();
					d = d < 10 ? ('0' + d) : d;
					return y+'-'+m+'-'+d;
				}
				return '';
			},
			handleConfirmEffect(type){
				let checkData = '';
				switch(type){
					case 1:
					checkData = this.newCoupon;
					break;
					case 2:
					checkData = this.buyCoupon;
					break;
				}
				if(!checkData){
					this.$message.error(this.$t('liveplatform.errorMsg.addBenefits'));
					return false;
				}
				let param = {
					coupon_id: checkData.coupon_id,
					type: type,
					app_key: this.$store.getters.appKey
				}
				activeCoupon(param).then(response => {
					switch(type){
						case 1:
						this.newCouponId = response.data.live_coupon_setting_id;
						break;
						case 2:
						this.buyCouponId = response.data.live_coupon_setting_id;
						break;
					}
					this.$message.success(this.$t('liveplatform.successMsg.deploy'));
				})
			},
			handleCancelEffect(type){
				this.$confirm(this.$t('liveplatform.confirmMsg.benefitsText'),this.$t('liveplatform.confirmMsg.title'),{
					confirmButtonText: this.$t('liveplatform.confirmMsg.confirmBtn'),
					cancelButtonText: this.$t('liveplatform.confirmMsg.cancelBtn'),
					type: 'warning'
				}).then(() => {
					let setId = 0;
					switch(type){
						case 1:
						this.newCoupon = '';
						setId = this.newCouponId;
						break;
						case 2:
						this.buyCoupon = '';
						setId = this.buyCouponId;
						break;
					}
					if(setId>0){
						let param = {
							type: type,
							live_coupon_setting_id: setId,
							app_key: this.$store.getters.appKey
						}
						cancelCoupon(param).then(() => {
							this.$message.success(this.$t('liveplatform.successMsg.delete'));
						})
					}
				}).catch(() => {
					this.$message(this.$t('liveplatform.cancelMsg.delete'));
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page-container {
		border-radius: 6px;
		padding: 24px;
		background-color: $--live-background-default;
		display: flex;

		.page-main {
			flex: 1;
			display: block;

			.item {
				.item-head {
					font-size: 16px;
					color: #FFFFFF;
					height: 24px;
					line-height: 24px;
					padding: 16px 0;
					border-bottom: 1px solid $--live-gray-white-4;
				}

				.item-main {
					color: $--live-text-color-2;
					padding: 24px;
					font-size: 14px;
					.item-link {
						color: $--live-link-color-1;
						cursor: pointer;
						font-weight: bold;
						padding-left: 10px;
						&:focus,&:hover{
							color: $--live-input-hover-2;
						}
					}

					.item-desc {
						line-height: 22px;
					}

					.item-text {
						padding-top: 20px;
						line-height: 32px;

						.el-button--uudanger {
							margin: 0 20px;
							color: $--live-button-primary-font;
							border-color: $--live-button-primary-border;
							background-color: transparent;
							&:focus,&:hover {
								color: $--live-button-primary-hover-font;
								border-color: $--live-button-primary-hover-border;
								background-color: transparent;
							}
						}

						.coupon-name {
							color: #333333;
							margin-right: 10px;
							padding: 0 10px;
							font-size: 14px;
							height: 32px;
							line-height: 32px;
							background-color: #eee;
							display: inline-block;
							vertical-align: middle;
						}
					}

					.item-foot {
						padding-top: 20px;

						.el-button--uudanger {
							color: #FFFFFF;
							border-color: $--live-button-primary-border;
							background-color: $--live-button-primary-background;
							&:focus,&:hover {
								color: #FFFFFF;
								border-color: $--live-button-primary-hover-border;
								background-color: $--live-button-primary-hover-background;
							}
						}

						.el-button--uublock {
							color: $--live-text-color-2;
							border-color: $--live-gray-white-12;
							background-color: transparent;
							&:focus,&:hover {
								color: $--live-button-default-hover-font;
								border-color: $--live-button-default-hover-border;
								background-color: transparent;
							}
						}
					}
				}
			}
		}

		.page-bar {
			width: 370px;
			padding-right: 35px;
			box-sizing: border-box;
			margin-left: 6px;
		}

		::v-deep .el-dialog__body {
			padding: 20px;
		}

		.dialog-equity {
			width: 100%;
			height: 480px;

			.uu-screen {
				height: 28px;
				margin-bottom: 15px;

				ul {
					float: left;
					white-space: nowrap;

					li {
						line-height: 28px;
						display: inline-block;

						span {
							margin-right: 10px;
						}
					}
				}

				.action {
					float: right;
					white-space: nowrap;
				}
			}

			::v-deep .el-table {
				.has-gutter {
					th {
						background-color: #ebecf0;
					}
				}
			}
			::v-deep .el-radio {
				margin: 0 ;
			}
		}
		.dialog-foot {
			padding-top: 10px;
			text-align: center;
		}
	}
</style>
